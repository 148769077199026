<template>
    <div class="overflow-y-scroll flex flex-col min-h-screen">
        <Menu />
        <slot />
        <div class="flex justify-center items-center mb-10 p-12">
            <Smalllogo />
        </div>
        <Footer />
    </div>
</template>
