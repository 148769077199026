<template>
    <svg
        version="1.1"
        width="200px"
        height="100px"
        viewBox="0 0 537.45706 222.20001"
        id="svg7"
        sodipodi:docname="Di Flos – Hnědá – Text.svg"
        inkscape:version="1.3.2 (091e20e, 2023-11-25)"
        xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
        xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:svg="http://www.w3.org/2000/svg"
    >
        <defs id="defs7"></defs>
        <sodipodi
            id="namedview7"
            pagecolor="#ffffff"
            bordercolor="#000000"
            borderopacity="0.25"
            inkscape:showpageshadow="2"
            inkscape:pageopacity="0.0"
            inkscape:pagecheckerboard="0"
            inkscape:deskcolor="#d1d1d1"
            inkscape:document-units="pt"
            inkscape:zoom="0.79657961"
            inkscape:cx="358.40736"
            inkscape:cy="148.13334"
            inkscape:window-width="2056"
            inkscape:window-height="1257"
            inkscape:window-x="0"
            inkscape:window-y="44"
            inkscape:window-maximized="1"
            inkscape:current-layer="svg7"
        ></sodipodi>
        <path
            transform="matrix(1,0,0,-1,0,105.75001)"
            d="M12 105.00001C16.4 105.200008 20.7 105.3 24.900002 105.3 29.2 105.3 33.700006 104.85 38.4 103.95 43.2 103.05 47.9 101.350009 52.500005 98.850009 57.200006 96.45 61.149999 93.3 64.35 89.4 67.649997 85.600009 70.299999 80.55 72.3 74.25 74.3 67.950008 75.3 60.3 75.3 51.3 75.3 42.3 73.80001 34.199998 70.80001 26.999993 67.90001 19.899994 63.9 14.450005 58.800004 10.650002 48.9 3.25 36.95-.450005 22.95-.450005 18.050002-.450005 12.900001-.25 7.5 .150002H0V105.00001H12ZM12.450003 1.349998C14.550003 1.150002 17.2 1.049995 20.400002 1.049995 23.6 1.049995 26.000002 1.100006 27.600003 1.200005 29.200003 1.300003 31.400002 1.700005 34.2 2.400002 37 3.200005 39.600004 4.249992 42.000005 5.549995 44.4 6.849998 46.9 8.749992 49.5 11.249992 52.2 13.849991 54.500005 16.900002 56.4 20.400002 60.700006 28.599999 62.850008 39.299997 62.850008 52.499998 62.850008 61.899999 61.7 70 59.4 76.8 57.100004 83.700008 54.45 88.8 51.45 92.100009 48.55 95.4 45.000005 98 40.800004 99.9 36.600004 101.90001 33.25 103.05 30.75 103.350009 28.25 103.65 25.350003 103.8 22.050004 103.8 18.750004 103.8 15.550003 103.65 12.450003 103.350009V1.349998Z"
            fill="#cb8763"
            id="path1"
        ></path>
        <path
            transform="matrix(1,0,0,-1,0,105)"
            d="M104.78086 102.899997V104.399997H117.230869V102.899997 .899994-.600006H104.78086V.899994 102.899997Z"
            fill="#cb8763"
            id="path2"
        ></path>
        <path
            transform="matrix(1,0,0,-1,0,105)"
            d="M265.159 104.399997V102.899997H217.90898V54.89999H261.70899V53.39999H217.90898V-.600006H205.45899V104.399997H265.159Z"
            fill="#cb8763"
            id="path3"
        ></path>
        <path
            transform="matrix(1,0,0,-1,0,105)"
            d="M345.39845-.600006H288.39845V104.399997H300.84846V.899994H345.39845V-.600006Z"
            fill="#cb8763"
            id="path4"
        ></path>
        <path
            transform="matrix(1,0,0,-1,0,106.20001)"
            d="M357.49806 50.55C357.49806 68.75 361.89808 82.55 370.69807 91.950008 379.59806 101.450008 390.99806 106.20001 404.89805 106.20001 418.79804 106.20001 429.84803 102.10001 438.04804 93.90001 446.34803 85.70001 450.49806 72.600009 450.49806 54.600004 450.49806 36.600008 446.09803 23 437.29804 13.800003 428.49806 4.600006 416.74806 0 402.04804 0 389.34803 0 378.94807 3.650009 370.84806 10.950012 366.64805 14.750015 363.34806 20 360.94807 26.699997 358.64808 33.5 357.49806 41.449998 357.49806 50.55ZM404.44807 104.700008C394.34806 104.700008 386.04804 100.200008 379.54804 91.20001 373.14805 82.200008 369.94807 69 369.94807 51.600004 369.94807 34.200006 372.99806 21.5 379.09806 13.5 385.19807 5.5 393.29804 1.5 403.39805 1.5 413.59806 1.5 421.89805 6 428.29804 15 434.79804 24.099999 438.04804 37.250009 438.04804 54.45001 438.04804 71.75001 434.94807 84.450008 428.74806 92.55 422.64805 100.65 414.54808 104.700008 404.44807 104.700008Z"
            fill="#cb8763"
            id="path5"
        ></path>
        <path
            transform="matrix(1,0,0,-1,0,105.45)"
            d="M531.307 98.549999C522.20706 102.049999 513.607 103.79999 505.50703 103.79999 497.50703 103.79999 491.25703 102.09999 486.75703 98.7 482.25703 95.29999 480.00703 90.59999 480.00703 84.59999 480.00703 77.29999 483.80705 71.649997 491.40705 67.649997 494.80705 65.94999 498.50703 64.34999 502.50703 62.849988 506.60704 61.449987 510.707 59.84999 514.807 58.04999 518.907 56.249994 522.657 54.19999 526.057 51.89999 529.45706 49.59999 532.20706 46.39999 534.307 42.29999 536.407 38.299989 537.45706 33.149995 537.45706 26.84999 537.45706 20.649994 535.70706 15.349983 532.20706 10.949982 528.70706 6.54998 524.45706 3.549995 519.45706 1.949997 514.557 .449997 508.80705-.300003 502.20704-.300003 489.90705-.300003 479.40705 2.749992 470.70704 8.849991L471.45704 10.049988C474.75703 7.449989 479.00703 5.299988 484.20704 3.599991 489.50703 1.999992 494.90705 1.199997 500.40705 1.199997 508.50706 1.199997 515.40707 3.049988 521.10708 6.749985 526.90707 10.549988 529.807 16.09999 529.807 23.399994 529.807 31.399994 526.007 37.649995 518.40707 42.14999 515.007 44.14999 511.25706 45.999986 507.15705 47.699987 503.15705 49.399988 499.10704 51.149988 495.00703 52.949987 490.907 54.849988 487.157 56.94999 483.75703 59.24999 476.157 64.249988 472.35704 71.34999 472.35704 80.549999 472.35704 87.84999 474.80705 93.749988 479.70704 98.249988 484.60704 102.84999 491.95704 105.149997 501.75703 105.149997 511.657 105.149997 521.70706 103.44999 531.90707 100.04999L531.307 98.549999Z"
            fill="#cb8763"
            id="path6"
        ></path>
        <path
            transform="matrix(1,0,0,-1,139.22852,180.15195)"
            d="M0-12.048004ZM28.504-12.048004 13.328 7.047997 8.792 2.343994V-12.048004H5.32V26.031996H8.792V6.711998L28.168002 26.871998 30.408003 24.743996 15.624001 9.399998 32.648-12.048004H28.504ZM41.595315-12.048004ZM45.571317 26.312 42.155317 25.751997 56.715314-12.048004H60.355318L74.18732 26.031996H70.883319L58.507318-7.568005 45.571317 26.312ZM83.13594-12.048004ZM110.183948-12.048004 109.84794-9.248005H91.92794V6.207996H105.64794L105.36794 8.727997H91.92794V23.512H108.89594L108.615947 26.031996H88.45594V-12.048004H110.183948ZM98.70394 31.8 94.559947 37.904 91.31194 37.343999 97.02394 29.335997H100.38394L105.98394 37.343999 102.90394 37.904 98.70394 31.8ZM119.7-12.048004ZM149.828 26.031996H120.259998V23.287999H133.42V-12.048004H136.948V23.287999H150.108L149.828 26.031996ZM159.05313-12.048004ZM167.84514-12.048004V26.031996H164.37314V-12.048004H167.84514ZM181.5625-12.048004ZM190.3545-12.048004V19.311997L191.1385 17.687996 212.3065-12.048004H214.60251V26.031996H211.0745V-4.824005L209.1705-1.520004 189.1785 26.031996H186.8825V-12.048004H190.3545ZM228.29843-12.048004ZM244.93044-12.048004 244.98644 3.799995 258.31443 26.031996H254.56244L243.19443 6.767998 231.93843 26.871998 228.85843 25.135998 241.45844 3.407997 241.40244-12.048004H244.93044Z"
            fill="#cb8763"
            id="path7"
        ></path>
    </svg>
</template>
