<template>
    <div
        class="flex flex-wrap min-w-[70%] mx-auto items-center justify-center sm:justify-between py-10"
    >
        <a href="/"> <Smalllogo /> </a>
        <ul class="flex space-x-4">
            <li>
                <NuxtLink to="/" class="text-[20px] uppercase hover:text-red-400 transition-all">
                    Domů
                </NuxtLink>
            </li>
            <li>
                <NuxtLink to="/kontakty" class="text-[20px] uppercase hover:text-red-400 transition-all">
                    Kontakty
                </NuxtLink>
            </li>
            <li>
                <NuxtLink to="/onas" class="text-[20px] uppercase hover:text-red-400 transition-all">
                    O nás
                </NuxtLink>
            </li>
        </ul>
    </div>
</template>
